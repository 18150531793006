import React, {useMemo} from "react";
import {FormProps, Typography, Button, Checkbox, Form, Space} from "antd";
import {
    ExportOutlined
} from '@ant-design/icons';

type FieldType = {
    'Agreed Terms & Conditions': boolean;
    'Agreed Privacy Policy': boolean;
};

type Props = {
  fields: { [key: string]: any };
  onFinish: FormProps<FieldType>["onFinish"];
    isSubmitted?: boolean;
};

export const Record: React.FC<Props> = ({ fields, onFinish, isSubmitted }) => {
    const [form] = Form.useForm<{ 'Agreed Terms & Conditions': boolean; 'Agreed Privacy Policy': boolean }>();
    const [submittable, setSubmittable] = React.useState<boolean>(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);
    const isSigned = useMemo(() => Boolean(fields['Agreed Terms & Conditions']), [fields]);
    const isAgreed = useMemo(() => Boolean(fields['Agreed Privacy Policy']), [fields]);

  return (
    <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={{
        'Agreed Terms & Conditions': isSigned,
        'Agreed Privacy Policy': isAgreed,
    }}>
      <Space direction="vertical" size="middle">
          <div>
              <Form.Item<FieldType>
                  style={{
                      marginBottom: 0
                  }}
                  rules={[
                      {
                          validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject(new Error('Please read and agree')),
                      },
                  ]}
                  name="Agreed Terms & Conditions"
                  valuePropName="checked"
              >
                  <Checkbox disabled={isSigned || isSubmitted}>
                      By clicking here you agree to{" "}
                      <Typography.Link target="_blank" href={fields?.["Terms & Conditions"]}>
                          Terms & Conditions <ExportOutlined />
                      </Typography.Link>
                  </Checkbox>
              </Form.Item>
              <Form.Item<FieldType>
                  style={{
                      marginBottom: 0
                  }}
                  rules={[
                      {
                          validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject(new Error('Please read and agree')),
                      },
                  ]}
                  name="Agreed Privacy Policy"
                  valuePropName="checked"
              >
                  <Checkbox disabled={isSigned || isSubmitted}>
                      By clicking here you agree to{" "}
                      <Typography.Link target="_blank" href={fields?.["Privacy Policy Agreement"]}>
                          Kimelo Privacy Policy <ExportOutlined />
                      </Typography.Link>
                  </Checkbox>
              </Form.Item>
          </div>
          <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!submittable || isSigned || isSubmitted}>
                  Confirm and finish
              </Button>
          </Form.Item>
      </Space>
    </Form>
  );
};
